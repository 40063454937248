import WhiteCardContainer from '../../../../../components/containers/forms/WhiteCardContainer';
import SchedulesList from './components/schedulesList/SchedulesList';
import FormFieldsContainer from '../../../../../components/containers/forms/FormFieldsContainer';
import ProfileFormHeader from '../../ProfileFormHeader/ProfileFormHeader';
import HalfRow from '../../../../../components/formElements/HalfRow';
import useDoctorSchedules from './hooks/useDoctorSchedules';
import FormButton from '../../../../../components/formElements/FormButton';
import FormFieldsFooter from '../../../../../components/containers/forms/FormFieldsFooter';
import FormSelectDrop from '../../../../../components/formElements/FormSelectDrop';
import CustomRedAlert from '../../../../../components/alerts/customRedAlert/CustomRedAlert';
import CustomSuccessModal from '../../../../../components/alerts/customSuccessModal/CustomSuccessModal';
import FormResetButton from '../../../../../components/formElements/FormResetButton';
import FormDatePicker from '../../../../../components/formElements/FormDatePicker';
import { ClockIcon } from '@heroicons/react/24/outline';
import { Controller } from 'react-hook-form';
import { setHours, setMinutes } from 'date-fns';
import { Listbox, Transition } from '@headlessui/react'
import { useEffect, useState } from 'react';


const statusOptions = [
    { id: true, label: 'Activo' },
    { id: false, label: 'Inactivo' }
];

const dayOptions = [
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado',
    'Domingo'
];

const intervalOptions = [
    { id: 15, label: 15 },
    { id: 30, label: 30 },
    { id: 45, label: 45 },
    { id: 60, label: 60 },
]

export default function DoctorSchedules(props) {
    const { schedules, queryAllDoctorSchedules, selectedUser } = props;
    const [selectedDay, setSelectedDay] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const {
        register,
        errors,
        control,
        handleSubmit,
        saveSchedules,
        isEditing,
        defaultValues,
        showErrorModal,
        errorBody,
        closeErrorMessage,
        showSuccessModal,
        successBody,
        onCloseSuccessMessage,
        setSelectedSchedules,
        resetState,
        isDirty,
        setValue,
        getValues
    } = useDoctorSchedules(queryAllDoctorSchedules, selectedUser, schedules);

    console.log(defaultValues);

    const handleDaySelection = (value) => {
        if (!isSelected(value)) {
            const daysSelected = daysArray(getValues('dayName'));
            const latestSelectedDays = new Set([
              ...daysSelected,
              dayOptions.find((el) => el === value)
            ]);
            setValue('dayName', Array.from(latestSelectedDays));
        } 
        else {
            handleDeselect(value);
        }
        setIsOpen(true);
    }

    const handleDeselect = (value) => {
        const latestSelectedDays = daysArray(getValues('dayName')).filter((el) => el !== value);
        setValue('dayName', latestSelectedDays);
        setIsOpen(true);
      }

    const isSelected = (value) => {
        return daysArray(getValues('dayName'))?.find((el) => el === value) ? true : false;
    }

    const daysArray = (days) => {
        if (days) {
            return Array.isArray(days) ? days : [days];
        } else {
            return [];
        }
    }

    return (
        <>
            <form action="#" method="POST" onSubmit={handleSubmit(saveSchedules)}>
                <WhiteCardContainer>
                    <FormFieldsContainer>
                        <ProfileFormHeader
                            title={!isEditing ? 'Nuevo Horario' : 'Actualizar Horario'}
                            initialText={`Ingresa Horarios generales en los que atiendes pacientes. 
                                Estos NO son los espacios de consulta, solamente es de carácter informativo.`}
                        />
                        <div className="grid grid-cols-6 gap-6">
                            <HalfRow>
                                {/* <FormSelectDrop
                                    label={'Dia'}
                                    identifier={'dayName'}
                                    options={dayOptions}
                                    defaultValue={defaultValues.dayName}
                                    helperText={'Requerido'}
                                    registration={register('dayName', { required: true })}
                                    errorMessage={errors.dayName ? 'Este campo es requerido' : null}
                                /> */}
                            <Controller name="dayName" control={control} defaultValue={defaultValues.dayName} rules={{ required: "Este campo es requerido" }} render={({ field, value, fieldState: { error } }) => (
                                <>
                                    <Listbox
                                        as="div"
                                        className="space-y-1"
                                        value={value}
                                        onChange={(value) => {
                                            // field.onChange([value]);
                                            handleDaySelection(value);
                                        }}
                                        open={isOpen}
                                        >
                                        {() => (
                                            <>
                                            <Listbox.Label className="block text-sm leading-5 font-medium text-gray-700">
                                                Dia
                                            </Listbox.Label>
                                            <div className="relative">
                                                <span className="inline-block w-full rounded-md shadow-sm">
                                                <Listbox.Button
                                                    className="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                                                    onClick={() => setIsOpen(!isOpen)}
                                                    open={isOpen}
                                                >
                                                    <span className="block truncate">
                                                    {daysArray(getValues('dayName'))?.length < 1
                                                        ? "Seleccionar días"
                                                        : `Días seleccionados (${daysArray(getValues('dayName')).length})`}
                                                    </span>
                                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                                    <svg
                                                        className="h-5 w-5 text-gray-400"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                        d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                    </span>
                                                </Listbox.Button>
                                                </span>

                                                <Transition
                                                unmount={false}
                                                show={isOpen}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                                className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10"
                                                >
                                                <Listbox.Options
                                                    static
                                                    className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                                                >
                                                    {dayOptions.map((day, index) => {
                                                    const selected = isSelected(day);
                                                    return (
                                                        <Listbox.Option key={day} value={day}>
                                                        {({ active }) => (
                                                            <div
                                                            className={`${
                                                                active
                                                                ? "text-white bg-blue-600"
                                                                : "text-gray-900"
                                                            } cursor-default select-none relative py-2 pl-8 pr-4`}
                                                            >
                                                            <span
                                                                className={`${
                                                                selected ? "font-semibold" : "font-normal"
                                                                } block truncate`}
                                                            >
                                                                {day}
                                                            </span>
                                                            {selected && (
                                                                <span
                                                                className={`${
                                                                    active ? "text-white" : "text-blue-600"
                                                                } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                                                                >
                                                                <svg
                                                                    className="h-5 w-5"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor"
                                                                >
                                                                    <path
                                                                    fillRule="evenodd"
                                                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                    clipRule="evenodd"
                                                                    />
                                                                </svg>
                                                                </span>
                                                            )}
                                                            </div>
                                                        )}
                                                        </Listbox.Option>
                                                    );
                                                    })}
                                                </Listbox.Options>
                                                </Transition>
                                                <div className="pt-1 text-sm">
                                                {getValues('dayName')?.length > 0 && (
                                                    <>Días seleccionados: {(Array.isArray(getValues('dayName')) ? getValues('dayName') : [getValues('dayName')]).join(", ")}</>
                                                )}
                                                </div>
                                            </div>
                                            </>
                                        )}
                                    </Listbox>
                                    {!errors.dayName && (
                                        <p className="mt-2 text-sm text-gray-500" id={`dayName-description`}>
                                            Requerido
                                        </p>
                                    )}
                                    {errors.dayName ? <p className="mt-2 text-sm text-red-700">Este campo es requerido</p> : null}
                                </>
                            )} />
                            </HalfRow>
                            <HalfRow>
                                <Controller
                                    control={control}
                                    name="interval"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <FormSelectDrop
                                            label={'Intervalo'}
                                            identifier={'interval'}
                                            options={intervalOptions}
                                            defaultValue={defaultValues.interval}
                                            helperText={'Requerido'}
                                            registration={register('interval', { required: true })}
                                            errorMessage={errors.interval ? 'Este campo es requerido' : null}
                                            onChange={(value) => field.onChange(value)}
                                        />
                                    )}
                                />
                                {console.log(errors, 'errors')}
                            </HalfRow>
                            <HalfRow>
                                <Controller
                                    control={control}
                                    name="openHours1"
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        return (
                                            <FormDatePicker
                                                identifier="openHours1"
                                                label="Seleccionar hora de inicio"
                                                errorMessage={errors.openHours1 ? 'Este campo es requerido' : null}
                                                helperText={'Requerido'}
                                                iconComponent={
                                                    <ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                }
                                                datePickerProps={{
                                                    onKeyDown: (e) => {
                                                        e.preventDefault();
                                                    },
                                                    selected: field.value,
                                                    dateFormat: 'HH:mm:ss',
                                                    onChange: (date) => field.onChange(date),
                                                    showTimeSelect: true,
                                                    showTimeSelectOnly: true,
                                                    timeIntervals: 15,
                                                    timeCaption: 'Hora',
                                                    dropdownMode: 'select',
                                                    minTime: setHours(setMinutes(new Date(), 0), 7),
                                                    maxTime: setHours(setMinutes(new Date(), 0), 20)
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </HalfRow>
                            <HalfRow>
                                <Controller
                                    control={control}
                                    name="openHours2"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <FormDatePicker
                                            identifier="openHours2"
                                            label="Seleccionar hora final"
                                            errorMessage={errors.openHours2 ? 'Este campo es requerido' : null}
                                            helperText={'Requerido'}
                                            iconComponent={
                                                <ClockIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            }
                                            datePickerProps={{
                                                onKeyDown: (e) => {
                                                    e.preventDefault();
                                                },
                                                selected: field.value,
                                                dateFormat: 'HH:mm:ss',
                                                onChange: (date) => field.onChange(date),
                                                showTimeSelect: true,
                                                showTimeSelectOnly: true,
                                                timeIntervals: 15,
                                                timeCaption: 'Hora',
                                                dropdownMode: 'select',
                                                minTime: setHours(setMinutes(new Date(), 0), 7),
                                                maxTime: setHours(setMinutes(new Date(), 0), 20)
                                            }}
                                        />
                                    )}
                                />
                            </HalfRow>
                            {isEditing && (
                                <HalfRow>
                                    <FormSelectDrop
                                        label={'Estado'}
                                        identifier={'status'}
                                        options={statusOptions}
                                        defaultValue={defaultValues.status}
                                        helperText={'Requerido'}
                                        registration={register('status', { required: true })}
                                        errorMessage={errors.status ? 'Este campo es requerido' : null}
                                    />
                                </HalfRow>
                            )}
                            <div className="h-40"></div>
                        </div>
                    </FormFieldsContainer>
                    <FormFieldsFooter>
                        <FormResetButton onClick={resetState} label={'Cancelar'} />
                        <FormButton
                            label={isEditing ? 'Actualizar' : 'Guardar'}
                            type={'submit'}
                            disabled={isEditing && !isDirty}
                        />
                    </FormFieldsFooter>
                </WhiteCardContainer>
                {showErrorModal && (
                    <CustomRedAlert
                        open={true}
                        title={'Ocurrió un error.'}
                        bodyText={errorBody}
                        onClose={closeErrorMessage}
                    />
                )}

                {showSuccessModal && (
                    <CustomSuccessModal
                        title={isEditing ? 'Registro actualizado con éxito' : 'Nuevo registro guardado con éxito'}
                        bodyText={successBody}
                        buttonText={'Aceptar'}
                        buttonAction={onCloseSuccessMessage}
                        open={showSuccessModal}
                        onClose={onCloseSuccessMessage}
                    />
                )}
            </form>
            <div className="ml-1">
                <div className="ml-5">
                    <ProfileFormHeader title={'Horarios existentes'} />
                </div>
                {schedules.length === 0 ? (
                    <p className="ml-4 mt-4">No hay horarios para mostrar</p>
                ) : (
                    <SchedulesList items={schedules} onClick={setSelectedSchedules} />
                )}
            </div>
        </>
    );
}
